// Components
import CardContainer from '@/components/ui/CardContainer'
import CardContainerElement from '@/components/ui/CardContainerElement'
import EditBillingData from '@/components/elements/company/EditBillingData'
import PlaceSubscriptions from '@/components/elements/place/PlaceSubscriptions'

export default {
  name: 'BillingData',
  components: {
    CardContainer,
    CardContainerElement,
    EditBillingData,
    PlaceSubscriptions
  }
}
