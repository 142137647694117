// Constants
import { ADDONS } from '@/constants'
// Components
import VuetifyDataTable from '@/components/ui/VuetifyDataTable'
import EditBillingInvoicesItems from '../EditBillingInvoicesItems'
// Mixins
import addonsMixin from '@/mixins/addonsMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuex
import { mapGetters } from 'vuex'
// Services
import { getInvoicesByEmail } from '@/services/place'
// Filters
import { formatDate, formatPrice } from '@/filters'
// Utils
import { get } from 'lodash'

export default {
  name: 'EditBillingInvoices',
  components: { EditBillingInvoicesItems, VuetifyDataTable },
  mixins: [addonsMixin, uiMixin],
  data() {
    return {
      // Table
      headers: [
        {
          text: 'Número',
          value: 'number'
        },
        {
          text: 'Fecha',
          value: 'date'
        },
        {
          text: 'Coste',
          value: 'amount'
        },
        {
          text: 'Estado',
          value: 'state'
        },
        { text: 'Descarga', align: 'center' }
      ],
      // Others
      invoices: [],
      processingRequest: false
    }
  },
  computed: {
    ...mapGetters('company', ['companyData']),
    /**
     * Email de facturación de la compañía
     *
     * @return {string}
     */
    billingEmail() {
      const companyConfig = get(this.companyAddonsSetupByUser, ADDONS.company, {})

      return companyConfig.billingEmail
    },
    /**
     * Facturas "parseadas" para la tabla
     */
    parsedInvoices() {
      return this.invoices.map((item) => {
        return {
          amount: formatPrice(item.total_cents / 100),
          date: formatDate(item.issue_date, 'D MMMM YYYY'),
          number: item.number,
          state: item.state,
          url: item.pdf
        }
      })
    }
  },
  watch: {
    billingEmail: {
      async handler(value) {
        if (value) {
          await this.setInvoices(value)
        }
      },
      immediate: true
    }
  },
  methods: {
    /**
     * Obtenemos las facturas asociadas al email
     *
     * @param {string} email - email de la compañía (faturación)
     * @return {array} - facturas
     */
    async setInvoices(email) {
      this.processingRequest = true
      try {
        this.invoices = await getInvoicesByEmail(email)
      } catch (error) {
        this.modifyAppAlert({
          text: error.message,
          type: 'error',
          visible: true
        })
      } finally {
        this.processingRequest = false
      }
    }
  }
}
