// Components
import VuetifyDataTable from '@/components/ui/VuetifyDataTable'
import EditBillingIncomingInvoiceItems from '../EditBillingIncomingInvoiceItems'
// Mixins
import uiMixin from '@/mixins/uiMixin'
// Vuex
import { mapGetters } from 'vuex'
// Services
import { getIncomingInvoiceBySubscriptionId } from '@/services/stripe'
import { getWalletTransactions } from '@/services/company'
// Filters
import { formatDate, formatPrice } from '@/filters'
// Utils
import { get, isNil } from 'lodash'

export default {
  name: 'EditBillingIncomingInvoice',
  components: { EditBillingIncomingInvoiceItems, VuetifyDataTable },
  mixins: [uiMixin],
  data() {
    return {
      // Table
      headers: [
        {
          text: 'Concepto',
          value: 'subject'
        },
        {
          text: 'Fecha',
          value: 'date'
        },
        {
          text: 'Coste',
          value: 'amount'
        }
      ],
      // Others
      invoices: [],
      processingRequest: false,
      walletBalance: 0
    }
  },
  computed: {
    ...mapGetters('place', ['placeData']),
    ...mapGetters('company', ['companyData', 'trialPlan']),
    /**
     * Opciones para la tabla
     *
     * @returns {Object}
     */
    dataTableVOptions() {
      return {
        'hide-default-footer': true,
        'disable-pagination': true,
        'disable-sort': true
      }
    },
    /**
     * Obtiene las (todas) suscripciones del establecimiento
     *
     * @return {Array}
     */
    stripeSubscriptionIds() {
      return this.trialPlan.daysLeft > -1
        ? []
        : get(this.placeData, 'subscriptions', [])
            .map((sub) => {
              return get(sub, 'stripeSubscriptionId', null)
            })
            .filter((subscription) => subscription)
    }
  },
  async mounted() {
    await this.getEveryNeededData()
  },
  methods: {
    /**
     * Obtenemos todos los datos necesarios para el componente
     */
    async getEveryNeededData() {
      this.processingRequest = true
      try {
        // Establecemos facturas
        this.invoices = [...(await this.getEveryIncomingInvoices())]
      } catch (error) {
        this.modifyAppAlert({
          text: error.message,
          type: 'error',
          visible: true
        })
      } finally {
        this.processingRequest = false
      }
    },
    /**
     * Obtenemos todos los futuros cobros de las suscripciones de
     * Stripe
     *
     * @return {array} - facturas
     */
    async getEveryIncomingInvoices() {
      // Todas las facturas posibles del establecimiento
      const invoices = await Promise.all(
        this.stripeSubscriptionIds.map(async (id) => {
          return await getIncomingInvoiceBySubscriptionId(id)
        })
      )
      const everyIsOk = invoices.every((result) => {
        return !isNil(result)
      })

      // Saldo acumulado en wallet
      const { walletAmount } = await getWalletTransactions(this.companyData.id)
      this.walletBalance = walletAmount

      if (!everyIsOk) {
        throw new Error(
          'Algunas de las peticiones de "futuras facturas" no respondieron correctamente.'
        )
      }

      // Parsemos la salida para la tabla con el descuento
      let currentWallet = this.walletBalance
      return invoices.map((item) => {
        const currency = get(item, 'currency', 'EUR')
        let hasDiscount = false
        let total = get(item, 'total', 0) / 100

        if (currentWallet > 0) {
          // Marcamos que posee descuento
          hasDiscount = true
          // Obtenemos total de la factura con el descuento
          if (currentWallet >= total) {
            // Restamos del total del saldo del usuario en Stripe
            currentWallet -= total
            // Descontamos valor de la factura (en este caso es 0)
            total = 0
          } else {
            // Descontamos valor de la factura
            total -= currentWallet
            // Restamos del total del saldo del usuario en Stripe (en este caso 0)
            currentWallet = 0
          }
        }
        return {
          amount: formatPrice(total, currency, true),
          date: formatDate(get(item, 'next_payment_attempt', 0) * 1000, 'D MMMM YYYY'),
          hasDiscount, // El usuario posee un descuento por saldo
          subject: get(item, 'lines.data.0.description', '-')
        }
      })
    }
  }
}
