// Constants
import { ADDONS, COMPANY_TYPES_BILLING } from '@/constants'
// Components
import FormButtons from '@/components/ui/FormButtons'
import GoogleMapAutocomplete from '@/components/ui/GoogleMapAutocomplete'
import StripeCreditCard from '@/components/ui/StripeCreditCard'
// Mixins
import addonsMixin from '@/mixins/addonsMixin'
import formMixin from '@/mixins/formMixin'
import intercomMixin from '@/mixins/intercomMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuelidate plugin
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
// Vuex
import { mapGetters, mapActions } from 'vuex'
// Services
import {
  createCardForCustomer,
  saveCustomer,
  createSetupIntent,
  confirmCardSetup
} from '@/services/stripe'
// Utils
import { get, cloneDeep, isNil } from 'lodash'
// Filters
import { formatCardNumber } from '@/filters'
// Validate
import { validDNI, validCIF, validNIE } from 'spain-id'

export default {
  name: 'EditBillingData',
  components: { FormButtons, GoogleMapAutocomplete, StripeCreditCard },
  filters: { formatCardNumber },
  mixins: [addonsMixin, formMixin, intercomMixin, uiMixin, validationMixin],
  data() {
    return {
      // Form
      formFields: {
        billingName: null,
        billingCompanyType: null,
        billingAddress: null,
        billingExtraAddress: null,
        cif: '',
        // Card
        cardId: null
      },
      formFieldsValidations: {
        billingName: {
          required: 'Campo obligatorio'
        },
        billingCompanyType: {
          required: 'Campo obligatorio'
        },
        billingAddress: {
          required: 'Debe seleccionar una de las direcciones sugeridas'
        },
        cif: {
          required: 'Campo obligatorio',
          vat: 'El número indicado no es valido para su pais de residencia'
        },
        // Card
        cardId: {
          required: 'Debes indicar una tarjeta'
        }
      },
      // Others
      companyTypes: Object.values(COMPANY_TYPES_BILLING),
      stripeCustomerId: null,
      cardWasEdited: false, // "Flag" tarjeta fue editada
      showPaymentMethodForm: false // "Flag" visibilidad formulario tarjeta
    }
  },
  computed: {
    ...mapGetters('company', ['companyData']),
    ...mapGetters('authentication', ['userData']),
    /**
     * ¿Tenemos datos de la tarjea?
     *
     * @return {object}
     */
    cardData() {
      return get(this.companyAddonsSetupByUser, `${ADDONS.company}.card`, {})
    },
    /**
     * CIF/NIF del usuario ya almacenado en BD
     *
     * @return {string}
     */
    currentCIFCustomer() {
      return get(this.companyAddonsSetupByUser, `${ADDONS.company}.cif`, {})
    }
  },
  watch: {
    cardData: {
      handler(value) {
        const cardId = get(value, 'id', null)
        this.showPaymentMethodForm = isNil(cardId)
      },
      immediate: true
    }
  },
  mounted() {
    this.getEveryNeededData()
  },
  methods: {
    ...mapActions('company', ['updateConfigs']),
    /**
     * Mostramos u ocultamos formulario "método de pago"
     */
    handleTogglePaymentMethodForm() {
      this.showPaymentMethodForm = !this.showPaymentMethodForm
    },
    /**
     * Obtengo todos los datos necesarios para el formulario
     */
    getEveryNeededData() {
      // Inicializa datos
      this.setFormFieldsValues()
    },
    /**
     * Set inital values in formulary field to set the validation correctly
     */
    setFormFieldsValues() {
      const companyConfig = get(this.companyAddonsSetupByUser, ADDONS.company, {})

      // Campos formulario
      this.formFields = {
        billingName: companyConfig.billingName || this.companyData.name,
        billingCompanyType: companyConfig.billingCompanyType || this.formFields.billingCompanyType,
        billingAddress: cloneDeep(companyConfig.billingAddress) || this.formFields.billingAddress,
        billingExtraAddress:
          companyConfig.billingExtraAddress || this.formFields.billingExtraAddress,
        cif: companyConfig.cif || this.formFields.cif,
        cardId: this.cardData.id || this.formFields.cardId
      }

      // Id de Stripe
      this.stripeCustomerId = get(companyConfig, 'stripeCustomerId', null)
    },
    /**
     * Método que se dispara antes de las validaciones
     */
    async beforeSubmit() {
      // Se está mostrando el formulario de la tarjeta
      // por tanto debe pedirse nuevo token
      if (this.showPaymentMethodForm) {
        // Obtenemos token de tarjeta (ID)
        const tokenCard = await this.$refs.stripeCard.createTokenCard()
        this.formFields.cardId = get(tokenCard, 'token.id', null)
        // Marcamos "flag" para que se pueda incluir la tarjeta
        this.cardWasEdited = true
      }
    },
    /**
     * Is triggering after the form is correctly
     * validated by "Vuelidate"
     */
    async afterSubmit() {
      // Separamos datos a almacenar
      const { cardId, ...otherFields } = this.formFields
      // Pais de facturación está en la zona SEPA
      const countryCode = get(otherFields.billingAddress, 'country_short', null)
      // Solo almacenamos el email en la creación
      const billingEmail = !this.stripeCustomerId ? this.userData.email : null

      // 1. Almacenamos "Datos de facturación" en Stripe
      const stripeData = {
        customer: {
          description: 'Cliente HORECA',
          address: {
            city: get(otherFields, 'billingAddress.locality', ''),
            country: get(otherFields, 'billingAddress.country_short', ''),
            line1: `${get(otherFields, 'billingAddress.route', '')} ${get(
              otherFields,
              'billingAddress.street_number',
              ''
            )}`,
            line2: get(otherFields, 'billingExtraAddress', ''),
            postal_code: get(otherFields, 'billingAddress.postal_code', ''),
            state: get(otherFields, 'billingAddress.administrative_area_level_2', '')
          },
          name: otherFields.billingName,
          // Solo mandamos el email en la creación
          ...(billingEmail ? { email: billingEmail } : {}),
          // Solo se manda si es Español
          ...(countryCode === 'ES' && this.currentCIFCustomer !== otherFields.cif
            ? {
                tax_id_data: [
                  {
                    value: otherFields.cif,
                    type: 'es_cif'
                  }
                ]
              }
            : {})
        },
        // Solo se envía si existe
        ...(this.stripeCustomerId ? { stripeCustomerId: this.stripeCustomerId } : {})
      }

      const { customer } = await saveCustomer(stripeData)

      if (!customer) {
        throw new Error(
          'No se ha podido crear el perfil correctamente. Comuníquese con su proveedor de servicio.'
        )
      }

      // Establecemos el stripeCustomerId recibido
      this.stripeCustomerId = customer.id

      // 2. Almacenamos "Datos de facturación" en Firebase

      // Actualizamos configuraciones
      await this.updateConfigs({
        id: this.companyData.id,
        addOns: [
          {
            id: ADDONS.company,
            configFields: {
              ...otherFields,
              stripeCustomerId: customer.id,
              // Solo mandamos el email en la creación
              ...(billingEmail ? { billingEmail } : {})
            }
          }
        ]
      })

      // 3. Creamos método de pago (tarjeta) en Stripe

      // La tajeta fue editada (creada) por lo que debo
      // crear el registro Stripe y Firestore
      if (this.cardWasEdited) {
        await this.saveCardData(cardId, customer.id)
        // Modificamos "flag" para que deba editarse de nuevo la tarjeta
        // para que esta sea incluida de nuevo en Stripe
        this.cardWasEdited = false
      }

      // Modificamos variable de Intercom
      this.intercomUpdate({ bakarta_facturacion_datos: true })

      // show info
      this.modifyAppAlert({
        text: 'Los cambios se guardaron correctamente',
        visible: true
      })
    },
    /**
     * Almacenamos tarjeta en Stripe y Firebase
     *
     * @param {string} cardId
     * @param {string} stripeCustomerId
     */
    async saveCardData(cardId, stripeCustomerId) {
      try {
        // 1. Asociamos tarjeta al usuario en Stripe
        const customer = await createCardForCustomer({
          stripeCustomerId,
          tokenCard: cardId
        })

        if (!customer) {
          throw new Error(
            'No se ha podido almacenar correctamente el método de pago. \
    Comuníquese con su proveedor de servicio.'
          )
        }

        // 2. Realizamos un "setupIntent" para que la tarjeta quede almacenada y el usuario
        // haya podido asegurar mediante 3D Secure que está fue incluida bajo su verificación
        // y podamos usarla para cobros posteriores sin nigún problema
        const setupIntent = await createSetupIntent({
          customer: stripeCustomerId,
          payment_method: customer.default_source // Método de pago por defecto
        })

        if (!setupIntent) {
          throw new Error('No hemos podido realizar la verificación a la tarjeta indicada.')
        }

        const { error } = await confirmCardSetup(setupIntent.client_secret)

        if (!isNil(error)) {
          // Lanzamos error
          throw new Error(error.message)
        }

        // 3. Almacenamos en Firebase (configuraciones)
        await this.updateConfigs({
          id: this.companyData.id,
          addOns: [
            {
              id: ADDONS.company,
              configFields: {
                card: {
                  id: get(customer, 'sources.data[0].id', null),
                  brand: get(customer, 'sources.data[0].brand', null),
                  exp_month: get(customer, 'sources.data[0].exp_month', null),
                  exp_year: get(customer, 'sources.data[0].exp_year', null),
                  last4: get(customer, 'sources.data[0].last4', null)
                }
              }
            }
          ]
        })

        // Modificamos variable de Intercom
        this.intercomUpdate({
          bakarta_facturacion_metodo_pago: true
        })
      } catch (error) {
        // Volvemos a colocar en "modo edición" la tarjeta
        this.showPaymentMethodForm = true
        // Capturamos aquí el error para realizar acciones sobre la tarjeya
        // y lo mandamos "hacía arriba" para que el sistema muestre el error
        // Lanzamos error
        throw new Error(error.message)
      }
    }
  },

  // Validations with Vuelidate
  validations() {
    const codeCountry = get(this.formFields.billingAddress, 'country_short', null)

    return {
      formFields: {
        billingName: {
          required
        },
        billingCompanyType: {
          required
        },
        billingAddress: {
          required
        },
        billingExtraAddress: {},
        cif:
          // Solo validamos a los españoles
          codeCountry === 'ES'
            ? {
                required,
                vat: (value) => {
                  // DNI, CIF or NIE
                  return validDNI(value) || validCIF(value) || validNIE(value)
                }
              }
            : {},
        // Campos de métodod de pago
        cardId: {
          required
        }
      }
    }
  }
}
