// Constants
import { PACKAGES } from '@/constants'
// Vuex
import { mapGetters } from 'vuex'
// components
import PlaceReactivateSubscription from '../PlaceReactivateSubscription'
import PlaceUnsubscription from '../PlaceUnsubscription'
// Mixins
import uiMixin from '@/mixins/uiMixin'
// Filters
import { formatDate } from '@/filters'
// Lodash
import { get } from 'lodash'

export default {
  name: 'PlaceSubscriptions',
  mixins: [uiMixin],
  data() {
    return {
      packages: PACKAGES
    }
  },
  computed: {
    ...mapGetters('place', ['placeData', 'subscribedPackages']),
    ...mapGetters('company', ['companyData', 'trialPlan']),
    /**
     * Obtenemos la subscripción actual que posee el establecimiento
     *
     * @return {object} - Datos de la actual suscripción
     */
    currentSubscribedPackage() {
      return Array.isArray(this.subscribedPackages) && this.subscribedPackages.length > 0
        ? this.subscribedPackages[0]
        : {
            active: true,
            packageId: PACKAGES.free.value
          }
    },
    /**
     * Suscripciones en formato "cadena" (etiquetas)
     */
    currentLabelSubscribedPackage() {
      const suffix =
        this.currentSubscribedPackage.active === false
          ? ` - Cancelado (${formatDate(this.currentSubscribedPackage.periodTo, 'L')})`
          : this.hasTrialVersion && this.currentSubscribedPackage.packageId !== PACKAGES.free.value
          ? ' - Trial '
          : ` (${formatDate(this.currentSubscribedPackage.periodTo, 'L')})`
      return PACKAGES[this.currentSubscribedPackage.packageId].label.concat(suffix)
    },
    /**
     * ¿Está vigente el periodo "TRIAL"?
     *
     * @return {boolean}
     */
    hasTrialVersion() {
      return this.trialPlan && this.trialPlan.daysLeft > -1
    },
    /**
     * Etique del botón de acción
     */
    labelActionButton() {
      return this.currentSubscribedPackage.active ? 'Cambiar plan' : 'Reactivar suscripción'
    },
    /**
     * Mostrar posibilidad de darse de baja del plan
     *
     * @return {boolean}
     */
    showUnsubscribeAction() {
      return this.currentSubscribedPackage.active
    }
  },
  methods: {
    /**
     * Pulsamos sobre el botón de acción, ya sea para redirigir a los
     * planes como para reactivar una subscripción cancelada
     */
    handleActionButton() {
      if (this.currentSubscribedPackage.active) {
        // Mostramos planes
        this.routerPushTo({
          name: 'plans'
        })
      } else {
        // Reactivamos plan cancelado
        this.modifyAppDialog({
          acceptButtonFn: null,
          title: 'Reactiva tu suscripción',
          contentComponent: PlaceReactivateSubscription,
          contentComponentProps: {
            stripeSubscriptionId: get(this.currentSubscribedPackage, 'stripeSubscriptionId', null),
            subscriptionId: get(this.currentSubscribedPackage, 'id', null)
          },
          hideActionButtons: true,
          visible: true
        })
      }
    },
    /**
     * Al pulsar en darse en el enlace de darse de baja
     */
    handleUnsubscribe() {
      this.modifyAppDialog({
        acceptButtonFn: null,
        title: '¿Cancelar suscripción?',
        contentComponent: PlaceUnsubscription,
        contentComponentProps: {
          stripeSubscriptionId: get(this.currentSubscribedPackage, 'stripeSubscriptionId', null),
          subscriptionId: get(this.currentSubscribedPackage, 'id', null)
        },
        hideActionButtons: true,
        visible: true
      })
    }
  }
}
