// Components
import FormButtons from '@/components/ui/FormButtons'
// Mixins
import uiMixin from '@/mixins/uiMixin'
// Vuex
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PlaceUnsubscription',
  components: { FormButtons },
  mixins: [uiMixin],
  props: {
    // ID de la suscripción a dar de baja
    subscriptionId: {
      required: true,
      type: String,
      default: null
    },
    // ID de la suscripción en Stripe
    stripeSubscriptionId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      formProcessing: false
    }
  },
  computed: {
    ...mapGetters('place', ['placeData'])
  },
  methods: {
    ...mapActions('place', ['cancelSubscriptionInPlace']),
    /**
     * Pulsamos sobre el botón "Cancelar"
     */
    handleCancelButton() {
      this.hideDialog()
    },
    /**
     * Pulsamos sobre el botón "Aceptar"
     */
    async handleAcceptButton() {
      try {
        // Loading
        this.formProcessing = true
        // Cancel subcription
        await this.cancelSubscriptionInPlace({
          canceledFor: 'Cancelada por el usuario',
          cancelInmediatly: false,
          placeId: this.placeData.id,
          stripeSubscriptionId: this.stripeSubscriptionId,
          subscriptionId: this.subscriptionId
        })
        // show info
        this.modifyAppAlert({
          text: 'La suscripción se canceló correctamente.',
          visible: true
        })
        // Cerramos modal
        this.hideDialog()
      } catch (error) {
        // Mensaje KO
        this.modifyAppAlert({
          text: error.message,
          type: 'error',
          visible: true
        })
      } finally {
        this.formProcessing = false
      }
    },
    /**
     * Hide dialog
     */
    hideDialog() {
      this.modifyAppDialog({
        visible: false
      })
    }
  }
}
