// Constants
import { ADDONS } from '@/constants'
// Components
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
import VuetifyTabs from '@/components/ui/VuetifyTabs'
import BillingData from '../BillingData'
import BillingInvoices from '../BillingInvoices'
// Vuex
import { mapGetters } from 'vuex'
// Mixins
import addonsMixin from '@/mixins/addonsMixin'
// Utils
import { get, cloneDeep } from 'lodash'

export default {
  name: 'BillingByTabs',
  components: {
    VuetifyToolBar,
    VuetifyTabs
  },
  mixins: [addonsMixin],
  data() {
    return {
      items: [
        {
          label: 'Configuración',
          component: BillingData
        }
      ],
      currentTabs: []
    }
  },
  computed: {
    ...mapGetters('company', ['companyData'])
  },
  created() {
    this.getEveryNeededData()
  },
  methods: {
    /**
     * Obtiene todos los datos necesarios para la vista
     */
    getEveryNeededData() {
      this.setCurrentTabs()
    },
    /**
     * Establecemos las "pestañas" que se mostrarán en el componente
     */
    setCurrentTabs() {
      const companyConfig = get(this.companyAddonsSetupByUser, ADDONS.company, {})

      this.currentTabs = cloneDeep(this.items)

      // Incluimos facturas (pestaña)
      if (companyConfig.stripeCustomerId) {
        this.currentTabs.push({
          label: 'Facturas',
          component: BillingInvoices
        })
      }
    }
  }
}
