// Components
import CardContainer from '@/components/ui/CardContainer'
import CardContainerElement from '@/components/ui/CardContainerElement'
import EditBillingInvoices from '@/components/elements/company/EditBillingInvoices'
import EditBillingIncomingInvoice from '@/components/elements/company/EditBillingIncomingInvoice'

export default {
  name: 'BillingInvoces',
  components: {
    CardContainer,
    CardContainerElement,
    EditBillingInvoices,
    EditBillingIncomingInvoice
  }
}
